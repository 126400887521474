import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

export default memo(({ data, isConnectable, id, ...props}) => {
  return (
    <>
      { !data.social && (
        <Handle
          type="target"
          position={Position.Left}
          onConnect={(params) => console.log("handle onConnect", params)}
          isConnectable={isConnectable}
        />
      )}
      <div 
        className="element-landingpage"
        style={{
          backgroundImage: `url(${data.image.url})`,
          height: data.image.height,
          width: data.image.width
        }}
      ></div>
      { data.label && (
        <>
          <EditText 
            className="element__title"
            inputClassName="element__title-input"
            defaultValue={data.label}
            onSave={(label) => {
              data.onChange && data.onChange(label.value, id);
            }}
          />
        </>
      )}
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable}
      />
    </>
  );
});
